import React from "react"
import Layout from "../Layout"
import { VacancyQuote } from "../components/rings/VacancyQuote"
import { TeamMembers } from "../components/rings/TeamMembers.js"
import { useStaticQuery, graphql } from "gatsby"
import { Seo } from "../components/Seo"
export default function Team() {
  const teamData = useStaticQuery(graphql`
    query Team {
      allSanityTrainer(
        sort: { fields: _createdAt }
        filter: { isShown: { eq: true } }
      ) {
        nodes {
          name
          _rawLongMessage
          image {
            asset {
              fluid(maxWidth: 1500) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <Seo urlLoc="https://www.onehappyhound.be/team" />
      <VacancyQuote />
      {teamData &&
        teamData.allSanityTrainer &&
        teamData.allSanityTrainer.nodes.length !== 0 && (
          <TeamMembers trainers={teamData.allSanityTrainer}></TeamMembers>
        )}
    </Layout>
  )
}

import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { WysiWyg } from "../core/WysiWyg"
import { size, mq, fontFamily } from "../../global.style"

export const TeamMembers = ({ trainers }) => {
  return (
    <TeamMembersStyled>
      {trainers.nodes.map((member, i) => (
        <TeamMember>
          <Text>
            <Title>{member.name}</Title>
            <Description>
              {member._rawLongMessage && (
                <WysiWyg blocks={member._rawLongMessage} />
              )}
            </Description>
          </Text>
          <Text>
            <StyledMemberImage
              fluid={member.image.asset.fluid}
              alt={member.name}
            />
          </Text>
        </TeamMember>
      ))}
    </TeamMembersStyled>
  )
}

const TeamMember = styled.div`
  max-width: 77rem;
  padding: 1rem;
  margin: 0 auto;

  :nth-child(even) {
    background-color: #f2f2f2;
  }

  ${mq.minWidth("1366px")} {
    max-width: initial;
    padding: 0;
    margin: 0;
    display: flex;
  }
`

const StyledMemberImage = styled(Img)`
  width: 100%;
  margin-bottom: 2rem;

  ${mq.minWidth(size.portrait)} {
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
  }

  ${mq.minWidth(size.landscape)} {
    width: 500px;
  }

  ${mq.minWidth("1366px")} {
    flex-shrink: 0;
    width: 450px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }
`

const TeamMembersStyled = styled.div``
const Text = styled.div`
  padding: 1rem 0;

  ${mq.minWidth(size.portrait)} {
    padding: 2rem 1rem;
  }

  ${mq.minWidth(size.laptop)} {
    padding: 3.5rem 2rem 1.5rem;
  }
`

const Description = styled.div`
  h3 {
    ${fontFamily("OpenSansExtraBold")};
    font-size: 1.25rem;
    margin: 0 0 1rem 0;

    ${mq.minWidth(size.portrait)} {
      font-size: 1.5rem;
      margin: 0 0 1rem 0;
    }

    ${mq.minWidth("1260px")} {
      margin: 0 0 1rem 0;
      font-size: 2rem;
    }
  }

  h4 {
    ${fontFamily("OpenSansExtraBold")};
    font-size: 0.85rem;
    margin: 0 0 1rem 0;

    ${mq.minWidth(size.portrait)} {
      font-size: 1rem;
      margin: 0 0 1rem 0;
    }

    ${mq.minWidth("1260px")} {
      margin: 0 0 3rem 0;
      font-size: 1.2rem;
    }
  }

  blockquote {
    ${fontFamily("OpenSansItalic")};
    margin: 1rem 0;
    padding-left: 1rem;
    font-size: 0.9rem;
    line-height: 1.6;

    strong {
      ${fontFamily("OpenSansBoldItalic")};
    }

    ${mq.minWidth(size.portrait)} {
      margin: 2.5rem 0;
      padding-left: 2rem;
    }

    ${mq.minWidth("1260px")} {
      margin: 5rem 0;
      line-height: 1.5;
      font-size: 1.5rem;
    }
  }

  li {
    ${mq.minWidth(size.phone)} {
      margin-bottom: 0.5rem;
    }

    ${mq.minWidth("1260px")} {
      margin-bottom: 0.5rem;
    }
  }

  ol,
  ul,
  p {
    ${fontFamily("OpenSansSemiBold")};
    font-size: 0.9rem;
    line-height: 1.6;

    strong {
      ${fontFamily("OpenSansExtraBold")};
    }

    ${mq.minWidth("1260px")} {
      font-size: 1.125rem;
    }
  }
`

const Title = styled.h2`
  ${fontFamily("OpenSansExtraBold")};
  margin: 0 0 0.5rem 0;
  font-size: 1.1rem;

  ${mq.minWidth(size.phone)} {
    font-size: 1.5rem;
    margin: 0 0 1rem 0;
    line-height: 0.8;
  }

  ${mq.minWidth("1260px")} {
    margin: 0 0 2rem 0;
    font-size: 2.5rem;
  }
`
